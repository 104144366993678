import React from 'react';

import Link from 'next/link';
import { BiStore } from 'react-icons/bi';
import { HiArrowNarrowRight, HiPlusCircle } from 'react-icons/hi';
import { useWindowSize } from 'usehooks-ts';

import { rudderstackTrackEvent } from '@/api/rudderstack/client';

const TayaraProductsSection = () => {
  const windowSize = useWindowSize();

  return (
    <div
      className={`relative w-full grow h-full !flex flex-col justify-start items-center gap-y-2 lg:block ${
        windowSize.width < 1024 && 'w-[900px] !hidden'
      }`}
    >
      <div
        className="relative w-full flex justify-center flex-col h-[300px] bg-neuf-200 rounded-xl p-4 lg:px-8 bg-no-repeat bg-[bottom_right_5rem]"
        style={{
          backgroundImage: `url(/media/ads.png)`,
        }}
      >
        <img
          className={`absolute w-[45%] top-0 right-10 z-10 ${
            windowSize.width > 1800 && 'w-[34%] top-2'
          }`}
          src="/media/shadowedHouse.png"
          alt=""
          width={600}
        />

        <div className="absolute bottom-0 left-0 w-full h-[50%] bg-gradient-to-t from-black/50 rounded-xl"></div>
        <div className="flex items-start flex-col z-10 ml-10">
          {/* To be removed after getting clients : remove the label */}
          {/* ===Label Coming soon==== */}
          {/* <div className="absolute -left-20 top-10 w-fit -rotate-45 py-1 px-[5rem]  bg-white">
            <span className="text-sm font-extrabold text-neutral-600 truncate">
              Bientôt disponible
            </span>
          </div> */}
          {/* ======= */}
          <span className="text-4xl md:text-5xl lg:text-6xl font-black text-white !text-start leading-none neons mt-10">
            ImmoNeuf
          </span>
          <span className="text-sm md:text-base w-1/2 leading-7  text-white my-2">
            Boostez votre visibilité et vendez vos biens immobiliers rapidement
            avec nos offres adaptées !
          </span>
          <span className="w-fit flex rounded-xl bg-blue-600 hover:bg-neuf-100 border-2 border-white px-8 my-2 cursor-pointer">
            <Link
              passHref
              href={'/immoNeuf/home'}
              aria-label="Découvrir ImmoNeuf"
              onClick={() =>
                rudderstackTrackEvent('Clicked on discover ImmoNeuf button')
              }
              className="flex items-center p-2 text-white "
            >
              <h3 className="mx-2 text-white  text-sm font-semibold">
                {/* Returnable after getting clients : replace Devenir un partenaire with Découvrir */}
                Voir Plus
              </h3>
              <HiArrowNarrowRight
                className="animate-go-right pt-[1.5px] mr-2 text-white "
                size={16}
              />{' '}
            </Link>
          </span>

          {/* Returnable after getting clients */}

          {/* <Link
              passHref
              href={'/bons-plans-tayara'}
              aria-label="Devenir un partenaire"
              onClick={() =>
                rudderstackTrackEvent('Clicked on devenir un partenaire button')
              }
              className="w-fit flex items-center font-semibold text-white underline"
            >
              Devenir un partenaire
            </Link> */}
        </div>
      </div>
      <div className="relative w-full h-[140px]  flex flex-wrap lg:flex-nowrap gap-2">
        {/* Boost card */}
        <div
          className="relative w-full min-w-[200px] mx-w-[552px] bg-yellow-400 rounded-xl px-8 py-4 bg-no-repeat bg-[center_right_1rem]"
          style={{
            backgroundImage: `url(/media/golden.png)`,
          }}
        >
          <img
            className=" absolute w-[20%] bottom-0 right-2 z-10"
            src="/media/rocket.png"
            alt=""
          />

          <div className="absolute bottom-0 left-0 w-full h-[40%] bg-gradient-to-t from-black/30 rounded-b-xl"></div>
          <div className=" w-full h-full flex justify-between flex-col">
            <span className="text-sm md:text-base uppercase font-extrabold text-neutral-900  z-10">
              Vendez Plus! gagnez plus!
            </span>
            {/* <span className="text-sm md:text-sm text-neutral-900  z-10 truncate">
                  Découvrir comment booster votre <br/>annonce pour vendre plus sur
                  Tayara
                </span> */}
            <span className="relative w-fit flex rounded-xl bg-white hover:bg-white/90 z-10 px-4 my-2 cursor-pointer border-2 border-neutral-800">
              <Link
                passHref
                href={'/services'}
                aria-label="Découvrir"
                onClick={() =>
                  rudderstackTrackEvent('Clicked on no offres button')
                }
                className="flex items-center p-2 text-neutral-800"
              >
                <h3 className="mx-2 pb-[1px] text-neutral-800 text-sm font-semibold truncate">
                  Découvrir nos offres
                </h3>
                <HiArrowNarrowRight
                  className=" mx-2 text-neutral-800"
                  size={20}
                />{' '}
              </Link>
            </span>
          </div>
        </div>

        <div
          className="relative w-full mx-w-[552px] bg-primary rounded-xl px-8 py-4 bg-no-repeat bg-[bottom_right_1rem]"
          style={{
            backgroundImage: `url(/media/ads.png)`,
          }}
        >
          <img
            className=" absolute w-[24%] bottom-0 right-0   z-10"
            src="/media/announcement.png"
            alt=""
          />

          <div className="absolute bottom-0 left-0 w-full h-[40%] bg-gradient-to-t from-black/50 rounded-b-xl"></div>
          <div className=" w-full h-full flex justify-between flex-col">
            <span className="text-sm md:text-base uppercase font-extrabold text-white  z-10">
              Vous avez quelque chose à vendre?
            </span>

            <span className="relative w-fit flex rounded-xl bg-primary hover:bg-primary-focus z-10 px-4 my-2 cursor-pointer border-2 border-white">
              <Link
                passHref
                href={'/post-listing'}
                aria-label="Découvrir"
                onClick={() =>
                  rudderstackTrackEvent('Clicked on ad insertion button')
                }
                className="flex items-center p-2 text-white"
              >
                <HiPlusCircle className=" mx-2 text-white" size={22} />
                <h3 className="mx-2 pb-[1px] text-white text-sm font-semibold truncate">
                  Publier une annonce
                </h3>
              </Link>
            </span>
          </div>
        </div>

        <div
          className="relative w-full mx-w-[552px] bg-blue-600 rounded-xl px-8 py-4 bg-no-repeat bg-[bottom_right_1rem]"
          style={{
            backgroundImage: `url(/media/ads.png)`,
          }}
        >
          <img
            className=" absolute w-[30%] bottom-0 right-0 z-10"
            src="/media/shop.png"
            alt=""
          />

          <div className="absolute bottom-0 left-0 w-full h-[40%] bg-gradient-to-t from-black/50 rounded-b-xl"></div>
          <div className=" w-full h-full flex justify-between flex-col">
            <span className="text-sm md:text-base uppercase font-extrabold text-white  z-10">
              Booster la visibilité de votre business!
            </span>

            <span className="relative w-fit flex rounded-xl bg-blue-500 hover:bg-blue-700 z-10 px-4 my-2 cursor-pointer border-2 border-white">
              <Link
                passHref
                href={'/leads/tayara-pro/'}
                aria-label="Découvrir"
                onClick={() =>
                  rudderstackTrackEvent('Clicked on open shop button')
                }
                className="flex items-center p-2 text-white"
              >
                <BiStore className=" mx-2 text-white" size={22} />{' '}
                <h3 className="mx-2 pb-[1px] text-white text-sm font-semibold truncate">
                  Ouvrir une boutique
                </h3>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TayaraProductsSection;
